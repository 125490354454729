export default {
  primary: {
    main: '#333333',
    green: '#00B778',
    bg: '#F7F7FA',
  },
  secondary: {
    main: 'rgba(221, 224, 228, 0.25)',
  },
  grey: {
    1000: '#000000', // Pure Black
    900: '#0D0D0D', // Main bg,
    800: '#BDBDBD', // Border
    700: '#8B8B8B', // Lang
    600: '#F6F7FB', // header
    500: '#C4C4C4',
  },
  link: {
    main: '#3179FF',
  },
  error: {
    main: '#E11E25',
  },
  success: {
    main: '#53B862',
  },
  input: {
    placeholder: 'rgba(25, 25, 27, 0.3)',
    borderColor: 'rgb(62,62,68)',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
}
