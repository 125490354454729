import styled, {createGlobalStyle} from 'styled-components'

export const collapseWidth = 992

export const height = 70
export const heightCollapsed = 60

export const Wrapper = styled.header`
  z-index: 1049;
  position: relative;
`

export const BodyPaddingStyle = createGlobalStyle`
  body {
    padding-top: ${height}px;
    @media (max-width: ${collapseWidth}px) {
      padding-top: ${heightCollapsed}px;
    }
  }
`
