import React, {useContext} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {
  MobileNavigationLinks,
  MobileNavigationWrapper,
  MobNaVDarkBg,
  MobNavLink,
  ButtonAction,
  TopRow,
  CloseIcon,
  CloseButton,
  ButtonWrapper,
} from './SideBar.styles'
import HeaderContext from '../HeaderContext'
import headerNavigationMob from '../../../data/headerNavigationMob'
import LanguagesDropdown from '../../LanguagesDropdown'

const MobNav = () => {
  const {t, language} = useI18next()
  const {opened, close} = useContext(HeaderContext)
  return (
    <>
      <CSSTransition in={opened} classNames="slide" timeout={300} unmountOnExit>
        <MobileNavigationWrapper>
          <TopRow>
            <LanguagesDropdown />
            <CloseButton onClick={close}>
              <CloseIcon />
            </CloseButton>
          </TopRow>
          <ButtonWrapper>
            <MobileNavigationLinks>
              {headerNavigationMob?.map(item => (
                <MobNavLink
                  key={item.textKey}
                  to={item.path}
                  partiallyActive={item.path !== '/'}
                  onClick={close}>
                  {t(item.textKey)}
                </MobNavLink>
              ))}
            </MobileNavigationLinks>
            <ButtonAction
              text={t('header.button')}
              w100
              onClick={close}
              href={language === 'de' ? '/#contactMob' : `/${language}/#contactMob`}
            />
          </ButtonWrapper>
        </MobileNavigationWrapper>
      </CSSTransition>
      <CSSTransition in={opened} classNames="fade" timeout={300} unmountOnExit>
        <MobNaVDarkBg onClick={close} />
      </CSSTransition>
    </>
  )
}

export default MobNav
