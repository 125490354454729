import React from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import ContentContainer from 'components/ContentContainer'
import Text from 'components/Text'
import {normalizePath} from 'utils'
import {graphql, useStaticQuery} from 'gatsby'
import {normalizePagePath} from 'gatsby/dist/utils/normalize-page-path'
import {
  Wrapper,
  LinksRow,
  Item,
  Block,
  Facebook,
  FacebookLink,
  BlockWrapper,
  DisplayMob,
  DisplayDesktop,
} from './Footer.styles'

const Footer = ({children, ...rest}) => {
  const {t, language} = useI18next()
  // console.log(t('footer.content', {returnObjects: true}))
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/facebook.png"}) {
        childImageSharp {
          fluid(maxWidth: 38) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <LinksRow>
          <BlockWrapper>
            <Block reverse>
              <Text size="sm" content={t('footer.copyright')} color="common.white" />
              <DisplayMob>
                <FacebookLink
                  href="https://www.facebook.com/kortenag"
                  rel="noreferrer noopener"
                  target="_blank">
                  <Facebook src={img} fluid={img.childImageSharp.fluid} />
                </FacebookLink>
              </DisplayMob>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <Text size="sm" content={t('footer.address1')} color="common.white" />
              <Text size="sm" content={t('footer.address2')} color="common.white" />
            </Block>
            <Block right mobFlexEnd>
              <Text size="sm" content={t('footer.tel')} color="common.white" />
              <Text size="sm" content={t('footer.fax')} color="common.white" />
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <Text size="sm" content={t('footer.email')} color="common.white" />
              <Text size="sm" content={t('footer.site')} color="common.white" />
            </Block>
            <Block right mobFlexEnd>
              <Item href="https://www.korten-ag.de/imprint">{t('footer.imprint')}</Item>
              <Item href="https://www.korten-ag.de/privacy-policy">{t('footer.privacy')}</Item>
            </Block>
          </BlockWrapper>
          <DisplayDesktop>
            <FacebookLink
              href="https://www.facebook.com/kortenag"
              rel="noreferrer noopener"
              target="_blank">
              <Facebook src={img} fluid={img.childImageSharp.fluid} />
            </FacebookLink>
          </DisplayDesktop>
        </LinksRow>
      </ContentContainer>
    </Wrapper>
  )
}

export default Footer
