import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = {
  mobileS: 321,
  mobileM: 375,
  mobileL: 425,
  tabletS: 600,
  tablet: 768,
  tabletL: 992,
  laptop: 1024,
  laptopS: 1199,
  laptopM: 1280,
  laptopL: 1440,
  desktop: 2560,
}

export default createBreakpoints({
  values: breakpoints,
})

// [theme.breakpoints.down('tabletL')]: {},
