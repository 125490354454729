import styled from 'styled-components'
import {Link} from 'gatsby'
import Img from 'gatsby-image'

export const Wrapper = styled.div(({theme}) => ({
  padding: '63px 0',
  backgroundColor: '#000000',
  color: 'rgba(255,255,255,0.7)',
}))

export const Block = styled.div(({theme, reverse, right, mobFlexEnd}) => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: 20,
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
    marginRight: 0,
    flexDirection: reverse ? 'row' : 'column',
    justifyContent: reverse ? 'space-between' : null,
    textAlign: right ? 'right' : 'left',
    marginBottom: 20,
    alignItems: mobFlexEnd ? 'flex-end' : 'flex-start',
  },
}))

export const BlockWrapper = styled.div(({theme}) => ({
  display: 'flex',
  width: '100%',
  maxWidth: 280,
  justifyContent: 'space-between',

  [theme.breakpoints.down('tablet')]: {
    maxWidth: 400,
  },
}))

export const LinksRow = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export const DisplayDesktop = styled.div(({theme}) => ({
  display: 'block',
  width: '100%',
  maxWidth: '38px',
  [theme.breakpoints.down('tablet')]: {
    display: 'none',
  },
}))

export const DisplayMob = styled.div(({theme}) => ({
  display: 'none',
  width: '100%',
  maxWidth: '16px',
  [theme.breakpoints.down('tablet')]: {
    display: 'block',
  },
}))

export const Item = styled(Link)(({theme, grey}) => ({
  fontSize: theme.fontSizes.sm,
  marginBottom: '7px',
  textDecoration: 'underline',
  color: theme.palette.common.white,
}))

export const FacebookLink = styled.a(({theme}) => ({
  maxWidth: '38px',
  width: '100%',
  height: '38px',
}))

export const Facebook = styled(Img)(({theme}) => ({
  width: '100%',
}))
