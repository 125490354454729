import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {languageMap} from './LanguagesDropdown.config'
import {
  ButtonTrigger,
  DropdownMenuLangWrapper,
  ItemLang,
  SelectIcon,
  ShortCode,
  Flag,
} from './LanguagesDropdown.styles'
import {
  Invisible,
  ItemArrow,
  ItemArrowPlaceHolder,
  ItemWrapper,
} from '../DropdownMenu/DropdownMenu.styles'
import useWindowSize from '../../hooks/helpers/useWindowSize'

const LanguagesDropdown = () => {
  const {language, languages, changeLanguage} = useI18next()
  const [dropdown, setDropdown] = useState(false)
  const size = useWindowSize()

  const onMouseEnterHandler = () => {
    if (size.width < 767) return

    setDropdown(true)
  }

  const onMouseLeaveHandler = () => {
    if (size.width < 767) return

    setDropdown(false)
  }

  const onClickHandler = () => {
    if (size.width > 767) return

    setDropdown(prevState => !prevState)
  }
  return (
    <>
      <ButtonTrigger
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={onClickHandler}>
        <ShortCode>{languageMap[language].textShort}</ShortCode>
        <SelectIcon />
        {dropdown && (
          <DropdownMenuLang
            data={languages}
            minWidth={170}
            changeLang={changeLanguage}
            language={language}
          />
        )}
      </ButtonTrigger>
    </>
  )
}

const DropdownMenuLang = ({data, minWidth, onClick, changeLang, language, ...rest}) => (
  <DropdownMenuLangWrapper {...rest} minWidth={minWidth}>
    <Invisible />
    {data.map(l => (
      <ItemWrapper key={l}>
        {language === l ? <ItemArrow /> : <ItemArrowPlaceHolder />}
        <Flag src={languageMap[l].icon} />
        <ItemLang
          onClick={() => {
            changeLang(l)
          }}>
          {' '}
          {languageMap[l].text}
        </ItemLang>
      </ItemWrapper>
    ))}
  </DropdownMenuLangWrapper>
)

export default LanguagesDropdown
