import React, {useContext} from 'react'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import {
  Container,
  Item,
  LinksWrapper,
  LogoLink,
  OtherActionsWrapper,
  Wrapper,
  LogoIcon,
  ButtonAction,
  BurgerStyled,
  WrapperSmall,
  Block,
  Icon,
  BlockText,
  ContainerSmall,
  Flag,
  LangBlock,
} from './TopBar.styles'
import HeaderContext from '../HeaderContext'
import LanguagesDropdown from '../../LanguagesDropdown'
import phoneIcon from '../../../images/index/nav/icon-phone.svg'
import mailIcon from '../../../images/index/nav/mail.svg'
import EnFlagIcon from '../../../images/index/nav/en-flag.svg'
import DeFlagIcon from '../../../images/index/nav/de-flag.jpeg'

const TopBar = ({...rest}) => {
  const {t, language} = useI18next()
  const {opened, toggle, isCollapsed, navItems} = useContext(HeaderContext)
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 242) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <WrapperSmall>
        <ContainerSmall>
          <Block>
            <Icon src={phoneIcon} />
            <a href="tel:+49408221822">
              <BlockText content="+49408221822" />
            </a>
          </Block>
          <Block>
            <Icon src={mailIcon} />
            <a href="mailto:claim@toll-claims.de">
              <BlockText content="claim@toll-claims.de" />
            </a>
          </Block>
          <LangBlock>
            <LanguagesDropdown />
            {/* {language === 'de' ? <Flag src={DeFlagIcon} /> : <Flag src={EnFlagIcon} />} */}
          </LangBlock>
        </ContainerSmall>
      </WrapperSmall>
      <Wrapper>
        <Container>
          <LogoLink to={language === 'de' ? '/' : `/${language}/`} aria-label="">
            <LogoIcon src={img} fluid={img.childImageSharp.fluid} />
          </LogoLink>
          <>
            {!isCollapsed && (
              <LinksWrapper>
                {navItems?.map(item => (
                  <Item key={item.textKey} to={item.path} partiallyActive={item.path !== '/'}>
                    {t(item.textKey)}
                  </Item>
                ))}
                <ButtonAction
                  to={language === 'de' ? '/#contact' : `/${language}/#contact`}
                  text={t('header.button')}
                />
              </LinksWrapper>
            )}
            {isCollapsed && (
              <OtherActionsWrapper>
                <BurgerStyled opened={opened} onClick={toggle} />
              </OtherActionsWrapper>
            )}
          </>
        </Container>
      </Wrapper>
    </>
  )
}

export default TopBar
