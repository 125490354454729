import styled from 'styled-components'
import ContentContainer from 'components/ContentContainer'

export const Wrapper = styled(ContentContainer)(({theme}) => ({
  position: 'absolute',
  top: 120,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3,
  pointerEvents: 'none',
  overflow: 'hidden',

  [theme.breakpoints.down('laptopS')]: {
    display: 'none',
  },
}))
