import styled from 'styled-components'
import {Link} from 'gatsby'
import React from 'react'
import ContentContainer from 'components/ContentContainer'
import Button from 'components/Button'
import Img from 'gatsby-image'
import Burger from '../Burger'
import {collapseWidth, height, heightCollapsed} from '../Header.styles'
import Text from '../../Text'

export const Wrapper = styled.div`
  background: ${({theme}) => theme.palette.common.white};
  width: 100%;
  height: ${height}px;
  position: fixed;
  top: 40px;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  @media (max-width: ${collapseWidth}px) {
    height: ${heightCollapsed}px;
    top: 40px;
  }
`

export const WrapperSmall = styled.div`
  background: ${({theme}) => theme.palette.grey[600]};
  width: 100%;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`

export const LogoIcon = styled(Img)(({theme}) => ({
  height: 55,
  width: 242,
}))

export const Icon = styled.img(({theme}) => ({
  marginRight: 10,
}))

export const Flag = styled.img(({theme}) => ({
  marginRight: 10,
  maxWidth: 25,
  width: '100%',
  height: 15,
}))

export const LinksWrapper = styled.div`
  max-width: 830px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
`

export const Block = styled.div`
  display: flex;
  margin-right: 0;

  &:first-of-type {
    margin-right: 33px;
  }
`

export const LangBlock = styled.div`
  @media (max-width: ${collapseWidth}px) {
    display: none;
  }
`

export const OtherActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled(ContentContainer)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
`

export const ContainerSmall = styled(ContentContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;

  @media (max-width: ${collapseWidth}px) {
    justify-content: flex-end;
  }
`

export const LogoLink = styled(Link)`
  max-width: 242px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const BlockText = styled(Text)(({theme}) => ({
  marginBottom: 0,

  [theme.breakpoints.down('mobileL')]: {
    fontSize: theme.fontSizes.sm,
  },

  [theme.breakpoints.down('mobileS')]: {
    fontSize: theme.fontSizes.xs,
  },
}))

export const Item = styled(Link).attrs(() => ({
  activeClassName: 'item--active',
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({theme}) => theme.palette.primary.light};
  font-size: ${({theme}) => theme.fontSizes.md};
  font-weight: 700;
  margin-left: 1em;
  transition-duration: 150ms;
  &.item--active {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: 800;
  }
  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`

export const ButtonAction = styled(props => <Button minWidth={270} {...props} />)(({theme}) => ({
  marginLeft: 85,
  height: 70,
  fontSize: theme.fontSizes.def,
  background: theme.palette.primary.green,
  color: '#FFFFFF',
  textTransform: 'uppercase',

  [theme.breakpoints.down('laptopS')]: {
    minWidth: 200,
  },
}))

export const BurgerStyled = styled(Burger)({
  padding: 5,
})
