module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Korten","short_name":"Korten","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/favicons/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"192eab76b0db5d97346ad4e3669cd150"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/var/www/toll-claims.de/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/var/www/toll-claims.de/i18n/locales","languages":["de","en","pl","cs","ru","nl","ro","hu"],"defaultLanguage":"de","i18nextOptions":{"keySeparator":".","nsSeparator":":","defaultNs":"translation","fallbackLng":"de","whitelist":["de","en","pl","cs","ru","nl","ro","hu"],"returnEmptyString":false,"returnNull":false,"wait":true,"detection":{"order":["querystring","localStorage","cookie","path","navigator"],"lookupQuerystring":"lang","lookupCookie":"CC","lookupLocalStorage":"lang","lookupFromPathIndex":0,"caches":["localStorage"],"checkWhitelist":true},"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
