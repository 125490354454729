import React, {useEffect, useState} from 'react'
import {ThemeProvider} from 'styled-components'
import Header from 'components/Header'
import Footer from 'components/Footer'
import FontFaces from 'styles/globalStyles/FontFaces'
import Reset from 'styles/globalStyles/Reset'
import theme from 'styles/theme'
import {SharedStateContextProvider} from 'hooks/helpers/useSharedState'
import SEO from '../SEO'
import FormBlock from '../FormBlock'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 300,
    offset: 80,
  })
}

const Layout = ({children, location}) => (
  <ThemeProvider theme={theme}>
    <SEO />
    <FontFaces />
    <Reset />
    <SharedStateContextProvider>
      {/* or api.registerPhone */}
      <Header />
      <FormBlock />
      {children}
      <Footer />
    </SharedStateContextProvider>
  </ThemeProvider>
)

export default Layout
