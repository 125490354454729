import styled from 'styled-components'
import Button from '../Button'
import Text from '../Text'

export const Wrapper = styled.div(({theme, mAuto, textAlign, modal, boxShadow}) => ({
  marginBottom: mAuto ? 0 : 30,
  marginLeft: modal ? 'initial' : 'auto',
  marginRight: mAuto ? 'auto' : 0,
  padding: '22px 25px',
  maxWidth: 370,
  background: theme.palette.common.white,
  pointerEvents: 'all',
  textAlign,
  boxShadow: boxShadow ? `0px 2px 15px rgba(0, 0, 0, 0.196487)` : 'none',

  [theme.breakpoints.down('laptopS')]: {
    margin: '0 auto',
  },
}))

export const FormButton = styled(Button)(({theme}) => ({
  fontSize: theme.fontSizes.def,
  display: 'flex',
  flexDirection: 'column',

  '& span': {
    fontSize: theme.fontSizes.xs,
    display: 'block',
    fontWeight: 400,
  },
}))

export const InputFile = styled.input(({theme}) => ({
  maxWidth: '100%',
  width: '100%',
  outline: 'none',
  height: 55,
  opacity: 0,
  position: 'absolute',
  zIndex: -1,
}))

export const InputLabel = styled.label(({theme}) => ({
  maxWidth: '100%',
  width: '100%',
  outline: 'none',
  height: 55,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #1B1B1B',
  cursor: 'pointer',
  marginBottom: 15,
}))

export const LabelRadio = styled.label(({theme}) => ({
  outline: 'none',
  cursor: 'pointer',
  marginLeft: 10,
  marginRight: 30,
}))

export const RadioWrapper = styled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 25,
  marginBottom: 26,
}))

export const RadioText = styled(Text)(({theme}) => ({
  marginRight: 67,
  marginBottom: 0,
}))

export const TextWrapper = styled.div(({theme, mAuto, textAlign, boxShadow}) => ({
  [theme.breakpoints.down('laptopS')]: {
    '& p': {
      textAlign: 'left',
    },
  },
}))
