export const headerNavigation = [
  {
    path: '#benefits',
    textKey: 'header.navigation.item1',
  },
  {
    path: '#performance',
    textKey: 'header.navigation.item2',
  },
  {
    path: '#aboutUs',
    textKey: 'header.navigation.item3',
  },
  {
    path: '#contact',
    textKey: 'header.navigation.item4',
  },
]
