import styled from 'styled-components'
import {Link} from 'gatsby'
import Icon from 'images/icons/nav-item-arrow.inline.svg'
import {collapseWidth} from '../Header/Header.styles'

export const Wrapper = styled.div(({theme, minWidth, posrel}) => ({
  padding: '17px 20px',
  position: posrel ? 'relative' : 'absolute',
  minWidth: posrel ? '100%' : minWidth,
  left: posrel ? 0 : -20,
  top: posrel ? 0 : 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: posrel ? null : theme.palette.common.white,
  boxShadow: posrel ? null : '0px 2px 15px rgba(0, 0, 0, 0.0964543)',
  textAlign: 'left',
  fontWeight: '400',
  pointerEvents: 'all',

  [theme.breakpoints.down(`${collapseWidth}`)]: {
    padding: '17px 0 0 0',
    position: 'static',
    boxShadow: 'none',
    minWidth: '100%',
  },
}))

export const Invisible = styled.div(({theme}) => ({
  position: 'absolute',
  left: 0,
  top: -10,
  width: '100%',
  height: 20,

  [theme.breakpoints.down(`${collapseWidth}`)]: {
    pointerEvents: 'none',
  },
}))

export const ItemWrapper = styled.div(({theme}) => ({
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',

  '&:last-of-type': {
    marginBottom: 0,
  },

  [theme.breakpoints.down(`${collapseWidth}`)]: {
    width: '100%',
  },
}))

export const Item = styled(Link)(({theme}) => ({
  [theme.breakpoints.down(`${collapseWidth}`)]: {
    width: '100%',
  },
}))

export const ItemArrow = styled(Icon)(({theme}) => ({
  marginRight: 14,
}))

export const ItemArrowPlaceHolder = styled.div(({theme}) => ({
  width: 6,
  height: 9,
  marginRight: 14,
}))
