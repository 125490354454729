import DeFlag from '../../images/common/de-flag.png'
import EnFlag from '../../images/common/united-kingdom.png'
import PlFlag from '../../images/common/poland.png'
import CzFlag from '../../images/common/czech-republic.png'
import HuFlag from '../../images/common/hungary.png'
import NlFlag from '../../images/common/netherlands.png'
import RoFlag from '../../images/common/romania.png'
import RuFlag from '../../images/common/russia.png'

export const languageMap = {
  de: {
    iconId: 'de',
    icon: DeFlag,
    textShort: 'DE',
    text: 'Deutsch',
  },
  en: {
    iconId: 'gb',
    icon: EnFlag,
    textShort: 'EN',
    text: 'English',
  },
  pl: {
    iconId: 'pl',
    icon: PlFlag,
    textShort: 'PL',
    text: 'Polski',
  },
  cs: {
    iconId: 'cz',
    icon: CzFlag,
    textShort: 'Cs',
    text: 'Čeština',
  },
  ru: {
    iconId: 'ru',
    icon: RuFlag,
    textShort: 'RU',
    text: 'Русский',
  },
  nl: {
    iconId: 'nl',
    icon: NlFlag,
    textShort: 'NL',
    text: 'Nederlands',
  },
  ro: {
    iconId: 'ro',
    icon: RoFlag,
    textShort: 'RO',
    text: 'Română',
  },
  hu: {
    iconId: 'hu',
    icon: HuFlag,
    textShort: 'HU',
    text: 'Magyar',
  },
}
