import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next} from 'gatsby-plugin-react-i18next'
import {Wrapper} from './FormBlock.styles'
import FormAssessment from '../FormAssessment'

const FormBlock = ({children, location, categoryData, bodyHeight, ...rest}) => (
  <Wrapper {...rest}>
    <FormAssessment boxShadow />
  </Wrapper>
)

// FormBlock.propTypes = {}
// FormBlock.defaultProps = {}

export default FormBlock
