import React, {useRef} from 'react'
import {collapseWidth, Wrapper} from './Header.styles'
import TopBar from './TopBar'
import SideBar from './SideBar'
import {HeaderContextProvider} from './HeaderContext'

const Header = ({children, ...rest}) => {
  const navRef = useRef(null)
  return (
    <Wrapper ref={navRef} {...rest}>
      <HeaderContextProvider navRef={navRef} collapseWidth={collapseWidth}>
        <TopBar {...rest} />
        <SideBar />
      </HeaderContextProvider>
    </Wrapper>
  )
}

export default Header
