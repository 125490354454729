import React, {useRef, useState} from 'react'
// import PropTypes from 'prop-types'
import Input from 'components/Input'
import {useI18next} from 'gatsby-plugin-react-i18next'
import Text from 'components/Text'
import * as yup from 'yup'
import {useFormik} from 'formik'

import {
  TextWrapper,
  FormButton,
  Wrapper,
  InputFile,
  InputLabel,
  LabelRadio,
  RadioWrapper,
  RadioText,
} from './FormAssessment.styles'

const FormAssessment = ({children, ...rest}) => {
  const {t} = useI18next()

  const formRef = useRef(null)
  const fileRef = useRef(null)

  const formik = useFormik({
    initialValues: {
      repayment_amount: '',
      email: '',
      surname: '',
      phone_number: '',
      attachment: '',
      // greeting: '',
      checkbox: false,
    },
    validationSchema: yup.object({
      email: yup.string().email().required('forms.incorrectEmailMessage'),
      repayment_amount: yup.number().required('forms.requiredMessage').positive().integer(),
      surname: yup.string().min(2, 'Mininum 2 characters').required('forms.requiredMessage'),
      // greeting: yup.string().required('forms.requiredMessage'),
      phone_number: yup.number().required('forms.requiredMessage').positive().integer(),
      checkbox: yup.bool().oneOf([true], 'forms.checkboxMessage'),
      attachment: yup
        .mixed()
        .required('forms.requiredMessage')
        .test(
          'fileSize',
          'forms.largeFileMessage',
          value => value && document.forms.subscribeform.attachment.files[0].size < 1048576
        ),
    }),
    onSubmit: () => {
      const data = new FormData(formRef.current)
      fetch('https://dev.laurus.agency/toll_claims/formRequest', {method: 'POST', body: data})
        .then(res => res.json())
        .then(res => {
          if (!res.success) throw res
          alert('Success')
        })
        .catch(err => {
          console.error(err)
        })
    },
  })

  return (
    <Wrapper {...rest} id="contact">
      <TextWrapper>
        <Text content={t('forms.assessment.title')} size="titleXs" mb={15} textAlign="center" />
        <Text content={t('forms.assessment.subtitle')} size="md" mb={15} textAlign="center" />
        <Text content={t('forms.assessment.text')} size="sm" mb={20} />
      </TextWrapper>
      <form
        ref={formRef}
        onSubmit={formik.handleSubmit}
        id="subscribeform"
        name="subscribeform"
        encType="multipart/form-data">
        <Input
          type="text"
          name="repayment_amount"
          id="repayment_amount"
          label={t('forms.assessment.labels.tolls')}
          uppercase
          value={formik.values.repayment_amount}
          onChange={formik.handleChange}
        />
        {formik.errors.repayment_amount && formik.touched.repayment_amount && (
          <Text content={t(formik.errors.repayment_amount)} color="error" />
        )}
        {/* <RadioWrapper> */}
        {/*  <RadioText content={t('forms.assessment.labels.greeting')} size="sm" /> */}
        {/*  <input */}
        {/*    name="greeting" */}
        {/*    id="Herr" */}
        {/*    type="radio" */}
        {/*    value="Herr" */}
        {/*    onChange={formik.handleChange} */}
        {/*  /> */}
        {/*  <LabelRadio htmlFor="Herr">{t('forms.assessment.labels.greetingMr')}</LabelRadio> */}
        {/*  <input */}
        {/*    name="greeting" */}
        {/*    id="Frau" */}
        {/*    type="radio" */}
        {/*    value="Frau" */}
        {/*    onChange={formik.handleChange} */}
        {/*  /> */}
        {/*  <LabelRadio htmlFor="Frau">{t('forms.assessment.labels.greetingMrs')}</LabelRadio> */}
        {/* </RadioWrapper> */}
        {/* {formik.errors.greeting && <Text content={t(formik.errors.greeting)} color="error" />} */}
        <Input
          type="text"
          name="surname"
          id="surname"
          label={t('forms.assessment.labels.Name')}
          uppercase
          value={formik.values.surname}
          onChange={formik.handleChange}
        />
        {formik.errors.surname && formik.touched.surname && (
          <Text content={t(formik.errors.surname)} color="error" />
        )}
        <Input
          type="email"
          name="email"
          id="email"
          label={t('forms.assessment.labels.email')}
          uppercase
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && formik.touched.email && (
          <Text content={t(formik.errors.email)} color="error" />
        )}

        <Input
          type="number"
          name="phone_number"
          id="phone_number"
          label={t('forms.assessment.labels.phone')}
          uppercase
          value={formik.values.phone_number}
          onChange={formik.handleChange}
        />
        {formik.errors.phone_number && formik.touched.phone_number && (
          <Text content={t(formik.errors.phone_number)} color="error" />
        )}

        <Text content={t('forms.assessment.disclaimer')} size="sm" mb={20} />
        <InputLabel htmlFor="attachment">{t('forms.assessment.labels.file')}</InputLabel>
        <InputFile
          type="file"
          name="attachment"
          id="attachment"
          accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
          ref={fileRef}
          value={formik.values.attachment}
          onChange={formik.handleChange}
        />
        {formik.errors.attachment && <Text content={t(formik.errors.attachment)} color="error" />}
        <Input
          type="checkbox"
          name="checkbox"
          id="checkbox"
          label={[
            `${t('forms.assessment.labels.checkboxStart')}`,
            <a href="https://www.korten-ag.de/datenschutz">
              &nbsp;
              {t('forms.assessment.labels.checkboxLink')}
              &nbsp;
            </a>,
            `${t('forms.assessment.labels.checkboxEnd')}`,
          ]}
          checkbox
          noFlexLabel
          value={formik.values.checkbox}
          onChange={formik.handleChange}
        />
        {formik.errors.checkbox && <Text content={t(formik.errors.checkbox)} color="error" />}
        <div className="clear">
          <FormButton w100 variant="green" uppercase type="submit" id="subscribe" name="subscribe">
            {t('forms.assessment.btnSubmit')}
            <span>{t('forms.assessment.btnSubmitCaption')}</span>
          </FormButton>
        </div>
      </form>
    </Wrapper>
  )
}

FormAssessment.propTypes = {}
FormAssessment.defaultProps = {}

export default FormAssessment
